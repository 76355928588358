<template>
  <div class="user-panel__wrapper layout-vertical">
    <div class="padding-top-small padding-horizontal-large layout-inflexible layout-vertical" style="height: 32px; box-sizing: border-box;">
      <div class="layout-horizontal switch font-size-extra-small" @click="handleModeSwitchClick">
        <div class="switch-item switch-item__user-list"
          :class="{'is-active' : panelMode === 'user-list'}">
          <i class="fas fa-stream"></i>
        </div>
        <div class="switch-item switch-item__user-info"
          :class="{'is-active' : panelMode === 'user-info'}">
          <i class="fas fa-user"></i>
        </div>
      </div>
    </div>
    <div class="user-panel layout-horizontal"
        :class="[
            {
              'user-list-mode': panelMode === 'user-list',
              'user-info-mode' : panelMode === 'user-info',
              'animation-enable': isAnimationEnable,
            }
        ]" style="height: calc(100% - 32px); box-sizing: border-box">
      <user-list-panel :organization-id="organizationId" :user.sync="selectedUser" @update:user="handleUserChanged" class="user-list-panel"
        style="height: calc(100%);"></user-list-panel>
      <user-info-panel class="user-info-panel" :user="selectedUser" :organization-id="organizationId"  style="height: calc(100%);"
        @click:blank="handleModeSwitchClick"></user-info-panel>
    </div>
    <div style="height: 20px;" class="layout-inflexible"></div>
  </div>
</template>

<script>
import UserListPanel from "@/pages/user_bak/panel/UserListPanel";
import UserInfoPanel from "@/pages/user_bak/panel/UserInfoPanel";

export default {
  name: "UserPanel",
  components: {UserInfoPanel, UserListPanel},
  props: {
    organizationId: Number,
    mode: {
      type: String,
      default: 'user-list',
    },
    user: Object,
  },
  data() {
    return {
      panelMode: null,
      isAnimationEnable: false,
      selectedUser: null,
    }
  },
  watch: {
    mode: {
      handler() {
        this.panelMode = this.mode;
      },
      immediate: true
    },
    user: {
      handler() {
        this.selectedUser = this.user;
        if(this.selectedUser) {
          this.panelMode = 'user-info';
          this.$emit('update:mode', this.panelMode);
        }
      },
      immediate: true,
    }
  },
  methods: {
    handleModeSwitchClick() {
      this.isAnimationEnable = true;
      if(this.panelMode === 'user-list') {
        this.panelMode = 'user-info';
      } else {
        this.panelMode = 'user-list';
      }
      this.$emit('update:mode', this.panelMode);
    },
    handleUserChanged(user) {
      this.isAnimationEnable = true;
      this.selectedUser = user;
      this.panelMode = 'user-info';
      this.$emit('update:user', this.selectedUser);
      this.$emit('update:mode', this.panelMode);
    }
  }
}
</script>

<style scoped>

.user-panel__wrapper {
  box-sizing: border-box;
  overflow: hidden;
  background-color: white;
  width: 225px;
}

.user-panel {
  width: 200%;
  height: inherit;
  position: relative;
}

.user-panel.user-list-mode {
  left: 0px;
}

.user-panel.user-info-mode {
  left: -100%;
}

@keyframes slide-to-right {
  from {
    left: -100%;
  }
  to {
    left: 0px;
  }
}

@keyframes slide-to-left {
  from {
    left: 0px;
  }
  to {
    left: -100%;
  }
}

.user-panel.animation-enable.user-list-mode {
  animation-name: slide-to-right;
  animation-duration: .2s;
}

.user-panel.animation-enable.user-info-mode {
  animation-name: slide-to-left;
  animation-duration: .2s;
}

.user-panel .user-list-panel {
  width: 50%;
  height: inherit;
}

.user-panel .user-info-panel {
  width: 50%;
  height: inherit;
}

.switch {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  cursor: pointer;
}

.switch-item {
  background: white;
  border: 2px solid #f091a6;
  box-sizing: border-box;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #f091a6;
}

.switch-item:hover {
  box-shadow: 0 0 4px #f091a6;
}

.switch-item.is-active {
  color: white;
  background-color: #f091a6;
}

.switch-item:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.switch-item:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

</style>