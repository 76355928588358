<template>
  <div class="minitool-entry-panel layout-vertical">
    <div class="font-size-extra-small font-color-placeholder font-align-center" v-if="miniToolEntries.length === 0">
      无小工具
    </div>
    <div v-for="miniToolEntry in miniToolEntries" :key="miniToolEntry.tag" class="margin-top-large">
      <mini-tool-entry :entry="miniToolEntry" @click.native="handleEntryClick(miniToolEntry)"></mini-tool-entry>
    </div>
    <mini-tool
        ref="minitool"
        style="height: 100%;"
        :entry.sync="currentMiniTool"
        :entries="miniToolEntries"
        :user-id="userId"
        :organization-id="organizationId"
        :loading-code="miniToolLoadingCode"
        :visible.sync="miniToolVisible"
    ></mini-tool>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import MiniToolEntry from "@/pages/minitool/basic/MiniToolEntry";
import MiniTool from "@/pages/minitool/basic/MiniTool";
import BackButton from "@/components/backbutton/BackButton";

function doLoadMiniToolEntries() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = 0;
  this.$reqGet({
    path: '/minitool/list/get/by/user/organization',
    data: {
      userId: this.userId,
      organizationId: this.organizationId,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.miniToolEntries, res.data, "tag");
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "MiniToolEntryPanel",
  components: {BackButton, MiniTool, MiniToolEntry},
  mixins: [httpapi],
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,

      miniToolEntries: [],

      currentMiniTool: null,
      miniToolVisible: false,
      miniToolLoadingCode: 1,
    }
  },
  computed: {
    inputParams() {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.miniToolEntries = [];
        if (this.userId && this.organizationId) {
          this.loadMiniToolEntries();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadMiniToolEntries() {
      doLoadMiniToolEntries.bind(this)();
    },
    handleEntryClick(entry) {
      this.currentMiniTool = entry;
      this.miniToolVisible = true;
    },
    handleBackClick() {
      if (this.$refs.minitool.back() == 0) {
        this.miniToolVisible = false;
      }
    }
  }
}
</script>

<style scoped>

.minitool-entry-panel {
  width: 64px;
  background-color: white;
  border-radius: 16px;
  padding: 10px;
  box-shadow: 0 0 4px #bec0c0;
}


</style>