<template>
  <div>
    <!--<background></background>
    <div class="margin-left layout-horizontal layout-middle font-size-medium">
      <back-button @click="$router.back()"></back-button>
      <div class="margin-left">用户管理</div>
      <ys-input placeholder="搜索用户" prefix-icon="el-icon-search" class="margin-left-large margin-vertical input-search"
                dark hollow
                v-model="search">
      </ys-input>
    </div>-->
    <div style="height: calc(100vh - 10px); box-sizing: border-box;"
         class="layout-horizontal padding-horizontal padding-bottom-small margin-top">
      <div class="card-main layout-horizontal layout-flexible" style="height: 100%; max-width: 1500px;">
        <user-panel :organization-id="organizationId" :user.sync="user" @update:user="handleUserChanged"
                    class="layout-inflexible"></user-panel>
        <div class="layout-flexible layout-self-stretch">
          <animated-router-view
              :organization-id="organizationId"
              :user-id="user ? user.id : null"
              :account-id="accountId"
              :account-changed="handleAccountChanged"
              style="height: 100%;"></animated-router-view>
        </div>
      </div>
      <mini-tool-entry-panel
          :user-id="user ? user.id : null"
          :organization-id="organizationId"
          class="margin-left"
          style="margin-top: 80px; margin-bottom: 200px;"
      ></mini-tool-entry-panel>
      <!--<div class="card layout-inflexible margin-left font-align-center padding"
           style="width: 64px; margin-top: 80px; margin-bottom: 200px;">小工具
      </div>-->
    </div>
  </div>
</template>

<script>
import UserPanel from "@/pages/user_bak/panel/UserPanel";
import AnimatedRouterView from "@/components/animatedrouterview/AnimatedRouterView";
import httpapi from "@/assets/javascript/httpapi";
import MiniToolEntryPanel from "@/pages/minitool/panel/MiniToolEntryPanel";

function doLoadUserItem(userId) {
  if (this.loadingCode === this.busyLoadingCode) {
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/user/item/get',
    data: {
      id: userId,
      organizationId: this.organizationId,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.user = res.data;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  });
}

function doLoadUserItemByAccount(accountId) {
  if (this.loadingCode === this.busyLoadingCode) {
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/user/item/get/by_account',
    data: {
      accountId,
      organizationId: this.organizationId,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.user = res.data;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  });
}

export default {
  name: "User",
  mixins: [httpapi],
  components: {MiniToolEntryPanel, AnimatedRouterView, UserPanel},
  props: {
    organizationId: Number,
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,
      user: null,
      accountId: null,
    }
  },
  watch: {
    user: {
      handler() {
        if (this.user && this.$route.name !== 'consultancy_list') {
          this.$router.push({name: 'punch_record'});
        }
        this.accountId = this.user?.user?.account.id;
      },
      immediate: true,
    }
  },
  activated() {
    this.loadingCode++;
    let userId = this.$route.query.id;
    if (userId) {
      doLoadUserItem.bind(this)(userId);
    }
  },
  methods: {
    handleUserChanged() {
      this.loadingCode++;
    },
    handleAccountChanged(accountId) {
      if(this.user?.user?.account.id !== accountId) {
        doLoadUserItemByAccount.bind(this)(accountId);
      }
    }
  }
}
</script>

<style scoped>

.input-search {
  width: 400px;
}

.card-main {
  box-shadow: 0 0 32px #bec0c0;
  border-radius: 32px;
  overflow: hidden;
  height: 100%;
}


</style>