<template>
  <ys-dialog title="用户签约信息" :visible.sync="isVisible" @update:visible="visible=>$emit('update:visible', visible)">
    <div class="padding padding-large">
      <div v-if="contract">
        <div>签约账号：</div>
        <div class="margin-top layout-horizontal">
          <user-avatar :user="contract.account"></user-avatar>
          <div class="margin-left">
            <div>{{ contract.account.nickName }}</div>
          </div>
        </div>
        <div v-if="contract.state === ContractState.NORMAL"
             class="layout-vertical layout-left margin-top-large">
          <div class="mark-with-contract">
            <div>
              <i class="fas fa-pen-alt"></i> 签约用户
            </div>
            <div class="margin-top-large">
              <span>签约时间：</span>
              <span>{{ TimeUtils.formatNormal(contract.startTime) }}</span>
            </div>
          </div>
          <div class="margin-top-large">
            <div class="btn-stop-contract layout-vertical layout-center" @click="handleStopContractClick">
              <ys-button type="secondary" round lighting>
                <i class="fas fa-user-times font-color-danger"></i>
              </ys-button>
              <div class="font-size-small margin-top">停止签约</div>
            </div>
          </div>
        </div>
        <div v-else class="layout-vertical layout-left margin-top-large">
          <div class="mark-with-stopped-contract">
            <div>
              <i class="fas fa-user-times"></i> 已解约
            </div>
            <div class="margin-top-large">
              <span>签约时间：</span>
              <span>{{ TimeUtils.formatNormal(contract.startTime) }}</span>
            </div>
            <div class="margin-top-large">
              <span>解约时间：</span>
              <span>{{ TimeUtils.formatNormal(contract.stopTime) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mark-without-contract">
        <i class="fas fa-ban"></i> 用户未签约
      </div>
    </div>
    <delete-dialog
        title="解约用户"
        message="您确定要解约该用户吗？"
        confirm-button-text="停止签约"
        :visible.sync="stopRemindDialogVisible"
        @confirm="handleStopContractConfirm"
    ></delete-dialog>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import httpapi from "@/assets/javascript/httpapi";
import {ContractState} from "@/assets/javascript/contract-utils";
import UserAvatar from "@/components/user/UserAvatar";
import {TimeUtils} from "@/assets/javascript/time-utils";
import YsButton from "@/components/wedigets/YsButton";
import DeleteDialog from "@/components/dialog/DeleteDialog";

function doLoadContract() {
  if (this.loadingCode === this.busyLoadingCode) {
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = 0;
  this.$reqGet({
    path: '/contract/get/by/user/organization',
    data: {
      userId: this.userId,
      organizationId: this.organizationId,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.contract = res.data;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doStopContract(id) {
  if(this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = 0;
  this.$reqPost({
    path: '/contract/stop',
    data: {
      id,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.success('已停止签约');
    this.busyLoadingCode = 0;
    doLoadContract.bind(this)();
    this.$emit('stop:contract')
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "ContractManagementDialog",
  mixins: [httpapi],
  components: {DeleteDialog, YsButton, UserAvatar, YsDialog},
  props: {
    visible: Boolean,
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      ContractState,
      TimeUtils,

      isVisible: false,

      loadingCode: 1,
      busyLoadingCode: 0,

      contract: null,

      stopRemindDialogVisible: false,
    }
  },
  computed: {
    inputParams() {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    visible: {
      handler() {
        this.isVisible = this.visible;
      },
      immediate: true,
    },
    inputParams: {
      handler() {
        this.loadingCode++;
        this.contract = null;
        if (this.userId && this.organizationId) {
          this.loadContract();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadContract() {
      doLoadContract.bind(this)();
    },
    handleStopContractClick() {
      this.stopRemindDialogVisible = true;
    },
    handleStopContractConfirm() {
      if(this.contract && this.contract.state === ContractState.NORMAL) {
        doStopContract.bind(this)(this.contract.id);
      }
    }
  }
}
</script>

<style scoped>

.mark-without-contract {
  background-color: #f1f1f1;
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  color: #7d7c7c;
}

.mark-with-contract {
  background-color: #f091a6;
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  color: white;
}

.mark-with-stopped-contract {
  background-color: #f1f1f1;
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  color: #7d7c7c;
}

.btn-stop-contract {
  cursor: pointer;
}

</style>