<template>
  <el-dialog
      class="dialog-mini-tool"
      :visible.sync="isVisible"
      fullscreen
      @update:visible="visible=>$emit('update:visible', visible)">
    <div class="layout-horizontal layout-right" style="height: 100%;" @click.stop="handleMaskClick">
      <div class="mini-tool layout-flexible layout-self-stretch" style="max-width: 1500px;" @click.stop="()=>{}">
        <iframe
            v-if="isVisible"
            ref="iframe"
            class="iframe-mini-tool"
            :src="src"
            frameborder="0"
            style="height: 100%;"
            scrolling="no"
        ></iframe>
      </div>
      <div class="layout-inflexible margin-left">
        <div class="btn-exit font-color-light font-size-medium padding">
          <i class="far fa-times-circle"></i>
          <span class="margin-left-small">返回</span>
        </div>
        <div class="mini-tool-entries-panel padding margin-top">
          <div class="font-size-small font-align-center margin-bottom">小工具</div>
          <div v-for="entry in entries" :key="entry.tag" class="margin-top-small">
            <mini-tool-entry :entry="entry" @click.native.stop="handleClickEntry(entry)"></mini-tool-entry>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import MiniToolEntry from "@/pages/minitool/basic/MiniToolEntry";

export default {
  name: "MiniTool",
  components: {MiniToolEntry},
  mixins: [httpapi],
  props: {
    userId: Number,
    organizationId: Number,
    entry: Object,
    visible: Boolean,
    entries: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      isVisible: false,
      currentEntry: null,
      src: null,
    }
  },
  watch: {
    visible: {
      handler: function (visible) {
        this.isVisible = visible;
      },
      immediate: true,
    },
    entry: {
      handler: function (entry) {
        this.currentEntry = entry;
      },
      immediate: true,
    },
    currentEntry: {
      handler: function (currentEntry) {
        let src = null;
        if (currentEntry?.webIndexPage) {
          src = currentEntry.webIndexPage;
          if (currentEntry.webIndexPage.indexOf("?") > 0) {
            src += "&";
          } else {
            src += "?"
          }
          src += "userId=" + this.userId + "&organizationId=" + this.organizationId;
        }
        this.src = src;
      },
      immediate: true,
    }
  },
  methods: {
    handleClickEntry: function (entry) {
      this.currentEntry = entry;
    },

    handleMaskClick: function () {
      this.isVisible = false;
      this.$emit('update:visible', this.isVisible);
    }
  }
}
</script>

<style scoped>

.dialog-mini-tool >>> .el-dialog {
  background-color: transparent;
}

.dialog-mini-tool >>> .el-dialog__body {
  height: 100vh;
  padding: 10px;
  margin: 0px;
  box-sizing: border-box;
}

.dialog-mini-tool >>> .el-dialog__header {
  visibility: hidden;
  height: 0px;
  margin: 0px;
  padding: 0;
}

.mini-tool {
  background-color: whitesmoke;
  overflow: hidden;
  border-radius: 32px;
}

.iframe-mini-tool {
  width: 100%;
}

.mini-tool-entries-panel {
  background-color: rgba(0, 0, 0, .6);
  border-radius: 10px;
  color: white !important;
}

.btn-exit {
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
}

.btn-exit:hover {
  background-color: rgba(0, 0, 0, .6);
}
</style>