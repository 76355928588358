<template>
  <ys-infinite-scroll-view class="user-list" @loadmore="loadMore">
    <div class="layout-vertical padding-horizontal">
      <div class="list-item padding padding-small layout-horizontal"
           :class="{'is-active': selectedUser && user.user.id === selectedUser.user.id}"
           v-for="user in userList" :key="user.user.id" @click="handleUserClick(user)">
        <div class="layout-vertical layout-center">
          <div class="avatar-container layout-inflexible"
               :class="{
                    'priority-high': user.priority && user.priority.priority === Priority.HIGH,
                    'priority-medium': user.priority && user.priority.priority === Priority.MEDIUM,
                    'priority-low': user.priority && user.priority.priority === Priority.LOW,
                }">
            <el-image class="image-avatar" fit="cover" :src="$prepareImageUrl(user.user.avatar)"
                      v-if="user.user.avatar"></el-image>
            <i class="fas fa-user font-size-extra-large font-color-secondary" v-else></i>
          </div>
          <div style="height: 0px; width: 0px;">
            <div class="mark-contract" v-if="user.withContract">签约</div>
          </div>
        </div>
        <div class="margin-left layout-flexible">
          <div>{{ user.user.name }}</div>
          <div class="layout-horizontal layout-middle"
               style="margin-top: 5px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 100%;">
            <div class="layout-flexible font-size-extra-small"
                 style="width: calc(100px); overflow: hidden; text-overflow: ellipsis;">
              <span class="margin-right-small" v-for="userTag in user.tags" :key="userTag.tag">{{ userTag.tag }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ys-infinite-scroll-view>
</template>

<script>

import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import Priority from "@/assets/javascript/prority";

function doLoadUserList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) {
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/user/list/get',
    data: {
      organizationId: this.organizationId,
      pageNum,
      pageSize: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.userList, res.data.list);
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  });
}

export default {
  name: "UserListPanel",
  mixins: [httpapi],
  components: {YsInfiniteScrollView},
  props: {
    organizationId: Number,
    user: Object,
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,
      userList: [],
      pageNum: 1,
      totalPages: 0,
      selectedUser: null,
      Priority,
    }
  },
  watch: {
    organizationId: {
      handler() {
        if (this.organizationId) {
          this.loadingCode++;
          this.pageNum = 1;
          this.totalPages = 0;
          this.userList = [];
          this.loadUserList();
        }
      },
      immediate: true,
    },
    user: {
      handler() {
        this.selectedUser = this.user;
      },
      immediate: true,
    }
  },
  methods: {
    loadUserList(pageNum = 1) {
      doLoadUserList.bind(this)(pageNum);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadUserList(this.pageNum + 1);
      }
    },
    handleUserClick(user) {
      this.selectedUser = user;
      this.$emit('update:user', user);
    }
  }
}
</script>

<style scoped>

.avatar-container {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 4px solid #bec0c0;
  padding-left: 0px;
  overflow: hidden;
  box-shadow: 0 0 4px #bec0c0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #bec0c0;
  background: #f1f1f1;
  box-sizing: border-box;
}

.avatar-container.priority-high {
  border: 4px solid #f091a6;
  box-shadow: 0 0 4px #f091a6;
}

.avatar-container.priority-medium {
  border: 4px solid #e6a23c;
  box-shadow: 0 0 4px #e6a23c;
}

.avatar-container.priority-low {
  border: 4px solid #98c9a9;
  box-shadow: 0 0 4px #98c9a9;
}

.mark-contract {
  background: #f091a6;
  color: white;
  border-radius: 50px;
  display: flex;
  padding: 0px 8px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 14px;
  width: 20px;
  white-space: nowrap;
  position: relative;
  text-align: center;
  top: -10px;
  left: -17px;
}

.user-list {
  height: inherit;
  width: inherit;
}

.list-item {
  min-width: auto !important;
  width: auto;
  background-color: #f1f1f1;
}

.list-item:hover {
  background-color: #faf4f5;
}

.list-item.is-active {
  background-color: white;
}

</style>