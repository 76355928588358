<template>
  <div style="width: 225px;" @click="handleClickUserPanel">
    <ys-infinite-scroll-view style="height: 100%; width: inherit">
      <div v-if="user && userByOrganization" class="user-info layout-vertical layout-center padding-top padding-left" style="width: inherit">
        <div class="user-tags-container padding-horizontal layout-self-stretch">
          <div class="user-tags__wrapper" v-if="userByOrganization">
            <div class="user-tag font-size-extra-small"
                 v-for="(userTag, index) in userByOrganization.tags"
                 :key="userTag.id"
                 v-show="index < 4" @click.stop="handleSettingUserPriorityClick">
              <span v-if="index === 3 && userByOrganization.tags.length > 4">更多...</span>
              <span v-else>{{ userTag.tag }}</span>
            </div>
          </div>
        </div>
        <div class="avatar-container" style="margin-top: 20px;"
             :class="{
                    'priority-high': userByOrganization && userByOrganization.priority && userByOrganization.priority.priority === Priority.HIGH,
                    'priority-medium': userByOrganization && userByOrganization.priority && userByOrganization.priority.priority === Priority.MEDIUM,
                    'priority-low': userByOrganization && userByOrganization.priority && userByOrganization.priority.priority === Priority.LOW,
                }">
          <div class="avatar__wrapper layout-vertical layout-center layout-middle">
            <el-image class="image-avatar" :src="$prepareImageUrl(user.user.avatar)" fit="cover"
                      v-if="user.user.avatar"></el-image>
            <i class="fas fa-user" v-else></i>
          </div>
        </div>
        <div class="margin-top font-size-large">{{ user.user.name }}</div>
        <div class="margin-top-small font-color-secondary" v-if="user.message" style="white-space: pre-wrap; line-height: 24px; text-align: center">{{user.message}}</div>

        <div class="layout-horizontal margin-top-large" @click.stop="()=>{}">
          <ys-popover>
            <el-tooltip slot="reference" :open-delay="500" content="联系方式">
              <div class="button-round" style="background-color: #c0a8c9;">
                <i class="fas fa-phone"></i>
              </div>
            </el-tooltip>
            <div>
              <div class="font-size-small">联系电话：</div>
              <div class="font-color-primary font-weight-bold margin-top-large font-size-large"
                   v-if="userByOrganization.user.phone">
                <span><i class="fa fa-phone"></i> {{ userByOrganization.user.phone.phone }}</span>
              </div>
              <div class="margin-top-large font-size-medium font-color-placeholder" v-else>未填写</div>
              <div class="font-size-small margin-top-large">联系地址：</div>
              <div class="font-size-medium" v-if="addressStr">
                <span><i class="fas fa-map-marker-alt"></i> {{ addressStr }}</span>
              </div>
              <div
                  class="margin-top-large font-size-medium font-color-placeholder"
                  v-else
              >未填写
              </div>
            </div>
          </ys-popover>
          <div class="button-round margin-left" style="background-color: #98c9a9;" @click.stop="handleConsultancyClick"
            v-show="$route.name !== 'consultancy_list'">
            <el-tooltip :open-delay="500" content="咨询">
              <el-badge value="新消息" :hidden="unreadMessageCount === 0">
                <div><i class="fas fa-comments"></i></div>
              </el-badge>
            </el-tooltip>
          </div>
          <div class="button-round margin-left" style="background-color: #98c9a9;" @click.stop="handleUserInfoClick"
            v-show="$route.name === 'consultancy_list'">
            <el-tooltip :open-delay="500" content="用户详情">
              <div><i class="fas fa-user"></i></div>
            </el-tooltip>
          </div>
        </div>

        <div
            class="button-square padding margin-top-large layout-self-stretch font-align-center margin-horizontal font-color-secondary"
            @click.stop="handleSettingUserPriorityClick" v-if="userByOrganization">
          设置用户标签和优先级
        </div>
        <div class="user-remark margin-top-large margin-horizontal layout-self-stretch" v-if="userByOrganization"
             @click.stop="()=>{$refs.remarkInput.focus()}">
          <div style="height: 0px; text-align: right">
            <i class="fas fa-paperclip" style="position: relative; font-size: 128px; color: #eed1a5; padding: 8px;"></i>
          </div>
          <div class="margin-top margin-horizontal-large" style="color: whitesmoke;"><i class="fas fa-pen-alt"></i> 备注
          </div>
          <ys-textarea ref="remarkInput" placeholder="请输入备注信息" style="width: 100%;" :show-background="false"
                       inner-class="input-user-remark"
                       placeholder-class="font-color-light"
                       class="font-size-large" v-model="userByOrganization.remark"
                       :maxlength="200"
                       @input="handleRemarkInput"></ys-textarea>
        </div>
        <div class="layout-flexible"></div>
        <div
            class="card card-contract-info padding padding-large layout-self-stretch margin-horizontal margin-vertical-large"
            v-if="userByOrganization && userByOrganization.contract"
            @click.stop="handleContractInfoClick">
          <div class="font-weight-bold">
            <span v-if="userByOrganization.contract.state === ContractState.NORMAL">签约用户</span>
            <span v-else><i class="fas fa-ban font-color-danger"></i> 已解约</span>
          </div>
          <div class="margin-top">签约时间：{{ contractStartTimeStr }}</div>
          <div class="margin-top" v-if="contractStopTimeStr">解约时间：{{ contractStopTimeStr }}</div>
        </div>
      </div>
      <user-tags-setting-dialog ref="userTagSettingDialog" :visible.sync="userTagSettingDialogVisible"
                                :organization-id="organizationId"
                                :user-by-organization="userByOrganization"></user-tags-setting-dialog>
      <contract-management-dialog
          :visible.sync="contractManagementDialogVisible"
          :user-id="user ? user.id : null"
          :organization-id="organizationId"
          @stop:contract="handleContractStopped"
      ></contract-management-dialog>
    </ys-infinite-scroll-view>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import YsTextarea from "@/components/wedigets/YsTextarea";
import Priority from "@/assets/javascript/prority";
import {TimeUtils} from "@/assets/javascript/time-utils";
import {ContractState} from "@/assets/javascript/contract-utils";
import UserTagsSettingDialog from "@/pages/user_bak/dialog/UserTagsSettingDialog";
import ContractManagementDialog from "@/pages/user_bak/dialog/ContractManagementDialog";
import YsPopover from "@/components/wedigets/YsPopover";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";

function doLoadUserByOrganization() {
  this.$reqGet({
    path: '/user/get/by_organization',
    data: {
      id: this.user.id,
      organizationId: this.organizationId,
    }
  }).then(res => {
    if (this.user && this.user.id === res.data.user.id) {
      this.userByOrganization = res.data;
    }
    this.loadUnreadMessageCount();
  });
}

function doSetUserRemark(userId, organizationId, remark) {
  this.$reqPost({
    path: '/user/remark/set',
    data: {
      userId,
      organizationId,
      remark,
    }
  });
}

function doLoadContract(userId, organizationId) {
  this.$reqGet({
    path: '/contract/get/by/user/organization',
    data: {
      userId,
      organizationId,
    }
  }).then(res => {
    if (this.user?.id === userId && this.organizationId === organizationId) {
      this.userByOrganization.contract = res.data;
    }
  })
}

function doLoadUnreadMessageCount() {
  let userId = this.user.id;
  let accountId = this.user.user.account.id;
  let organizationId = this.organizationId;
  this.$reqGet({
    path: '/consultancy/unread_count/get/of/account',
    data: {
      accountId,
      organizationId,
    }
  }).then(res => {
    if (this.user?.id === userId && this.organizationId === organizationId) {
      this.unreadMessageCount = res.data;
    }
  })
}

export default {
  name: "UserInfoPanel",
  components: {YsInfiniteScrollView, YsPopover, ContractManagementDialog, UserTagsSettingDialog, YsTextarea},
  mixins: [httpapi],
  props: {
    user: Object,
    organizationId: Number,
  },
  computed: {
    contractStartTimeStr: function () {
      return this.userByOrganization && this.userByOrganization.contract ? TimeUtils.formatNormal(this.userByOrganization.contract.startTime) : null;
    },
    contractStopTimeStr: function () {
      return this.userByOrganization && this.userByOrganization.contract && this.userByOrganization.contract.stopTime ? TimeUtils.formatNormal(this.userByOrganization.contract.stopTime) : null;
    },
    addressStr() {
      if (this.userByOrganization.user.address) {
        let address = this.userByOrganization.user.address;
        let result = ""
        if (address.province) {
          result += address.province;
        }
        if (address.city) {
          result += ' · ' + address.city;
        }
        if (address.district) {
          result += ' . ' + address.district;
        }
        if (address.detail) {
          result += ' . ' + address.detail;
        }
        return result;
      } else {
        return null;
      }
    }
  },
  data() {
    return {
      Priority,
      ContractState,
      userByOrganization: null,
      unreadMessageCount: 0,

      setRemarkTimeoutId: null,
      userTagSettingDialogVisible: false,


      contractManagementDialogVisible: false,
    }
  },
  watch: {
    user: {
      handler() {
        if (!this.userByOrganziation || this.user.id != this.userByOrganziation.user.id) {
          this.userByOrganziation = null;
          if (this.user) {
            this.loadUserByOrganization();
          }
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadUserByOrganization() {
      doLoadUserByOrganization.bind(this)();
    },
    loadUnreadMessageCount() {
      if (this.$route.name !== 'consultancy_list') {
        if (this.user && this.organizationId) {
          doLoadUnreadMessageCount.bind(this)();
        }
      }
    },
    handleSettingUserPriorityClick() {
      this.userTagSettingDialogVisible = true;
    },
    handleRemarkInput() {
      if (this.setRemarkTimeoutId) {
        clearTimeout(this.setRemarkTimeoutId);
      }
      let userId = this.user.id;
      let organizationId = this.organizationId;
      let remark = this.userByOrganization.remark;
      this.setRemarkTimeoutId = setTimeout(() => {
        doSetUserRemark.bind(this)(userId, organizationId, remark);
      }, 500);
    },
    handleConsultancyClick() {
      this.$router.push({name: 'consultancy_list'});
      this.unreadMessageCount = 0;
    },
    handleUserInfoClick() {
      this.$router.back();
    },
    handleClickUserPanel(evt) {
      this.$emit('click:blank', evt);
    },
    handleContractInfoClick() {
      this.contractManagementDialogVisible = true;
    },
    handleContractStopped() {
      doLoadContract.bind(this)();
    }
  },
  mounted() {
    window.eventBus.$on('notify:message', this.loadUnreadMessageCount);
  },
  destroyed() {
    window.eventBus.$off('notify:message', this.loadUnreadMessageCount);
  }
}
</script>

<style scoped>

.image-avatar {
  width: 108px;
  height: 108px;
  border-radius: 50%;
  filter: brightness(80%);
}

.avatar-container {
  box-sizing: border-box;
  border-radius: 50%;
  border: 4px solid #f1f1f1;
  background-color: white;
  box-shadow: 0 0 64px #c0a8c9;
  font-size: 64px;
  cursor: pointer;
}

.avatar-container.priority-high {
  border: 4px solid #f091a6;
}

.avatar-container.priority-medium {
  border: 4px solid #e6a23c;
}

.avatar-container.priority-low {
  border: 4px solid #98c9a9;
}

.avatar__wrapper {
  width: 108px;
  height: 108px;
  background-color: white;
  color: #bec0c0;
  padding: 5px;
  border-radius: 50%;
  overflow: hidden;
}

.button-round {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
}

.button-round:hover {
  box-shadow: 0 0 8px #bec0c0;
}

.button-square {
  background-color: #f1f1f1;
  cursor: pointer;
  border-radius: 16px;
}

.button-square:hover {
  box-shadow: 0 0 8px #bec0c0;
  color: #3a8ee6;
}

.user-tags-container {
  height: 0px;
  text-align: left;
}

.user-tags__wrapper {
  height: 128px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.user-tag {
  background-color: #f091a6;
  color: white;
  border-radius: 100px;
  padding: 4px 16px;
  white-space: nowrap;
  box-shadow: 0 0 16px #f091a6;
  backdrop-filter: blur(20px);
  z-index: 999;
  margin-top: 4px;
  cursor: pointer;
}

.user-tag:hover {
  filter: brightness(95%);
}

.user-tag:nth-child(1) {
  left: 10px;
  width: auto;
}

.user-tag:nth-child(2) {
  /*left: -30px;
  top: 104px;*/
  background-color: #c0a8c9;
  box-shadow: 0 0 16px #c0a8c9;
}

.user-tag:nth-child(3) {
  /*left: 108px;
  top: 64px;*/
  background-color: #d7ce3e;
  box-shadow: 0 0 16px #d7ce3e;
}

.user-tag:nth-child(4) {
  /*left: -40px;
  top: 24px;*/
}

.user-remark {
  background-color: #f0c78a;
  border-radius: 8px;
  box-shadow: 4px 4px 8px #bec0c0;
  overflow: hidden;
}

.input-user-remark >>> .el-textarea__inner {
  background: linear-gradient(transparent 30.5px, whitesmoke 1.5px);
  line-height: 32px;
  background-size: 100% 32px;
  background-attachment: local;
  box-sizing: border-box;
}

.input-user-remark >>> .el-textarea__inner::-webkit-input-placeholder {
  color: #f2ddbe;
}

.input-user-remark >>> .el-textarea__inner::-moz-placeholder {
  color: #f2ddbe;
}

.input-user-remark >>> .el-textarea__inner::-ms-input-placeholder {
  color: #f2ddbe;
}

.card-contract-info {
  cursor: pointer;
}

</style>